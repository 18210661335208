import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./config";
import { history } from "./helpers/history";

const onRedirectCallback = appState => {
	history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};

const config = getConfig();

const providerConfig = {
	domain: config.domain,
	clientId: config.clientId,
	onRedirectCallback,
	authorizationParams: {
		redirect_uri: window.location.origin,
		...(config.audience ? { audience: config.audience } : null),
	},
};

ReactDOM.render(
	<Auth0Provider {...providerConfig}>
		<App />
	</Auth0Provider>,
	document.getElementById("app")
);

reportWebVitals();
