import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Toast } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { useUserActions } from "../actions/user.actions";
import { DynamicForm } from "./DynamicForm/DynamicForm";
import { ComplianceStatusTypes } from "./DynamicForm/Enums";

const TransactionComplianceTable = forwardRef((props, ref) => {
	const userActions = useUserActions();
	const [showModal, setShowModal] = useState(false);
	const [selectedTransaction, setSelectedTransaction] = useState(null);
	const inputRef = useRef(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [page, setPage] = useState(1);
	const [selectedLimit, setSelectedLimit] = useState(10);
	const [totalPages, setTotalPages] = useState(1);
	const [transactions, setTransactions] = useState();
	const [showToast, setShowToast] = useState(false);

	const getTransactions = async () => {
		userActions
			.getTransactions(selectedLimit, page)
			.then(response => {
				const totalPages = Math.ceil(response.totalCount / response.limit);
				setTransactions(response.transactions);
				setTotalPages(totalPages);
				setCurrentPage(page);
			})
			.catch(error => {
				console.log(error);
			});
	};
	const handleEditClick = transactionId => {
		const selected = transactions.find(transaction => transaction.oskar_id === transactionId);

		setSelectedTransaction(selected);
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
		setSelectedTransaction(null);
		setFormState([]);
	};

	const handleLimitChange = event => {
		const newLimit = parseInt(event.target.value, 10);
		setSelectedLimit(newLimit);
		setPage(1);
	};

	const handleFormSubmit = (data, id) => {
		userActions
			.updateTransactionCompliance(data, id)
			.then(response => {
				console.log("POST Request Successful:", response);
				setShowToast(response.success === false);
				handleCloseModal();
			})
			.catch(error => {
				console.error("Error making POST request:", error);
			});
	};

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.style.height = "auto";
		}
		getTransactions();
	}, [selectedTransaction, selectedLimit, page]);
	const [formState, setFormState] = useState([]);

	return (
		<div className="row">
			<Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
				<Toast.Header>
					<strong className="mr-auto">Error</strong>
				</Toast.Header>
				<Toast.Body>Error updating transaction compliance. Please try again.</Toast.Body>
			</Toast>
			{transactions && transactions.length > 0 && (
				<div className="col-12">
					<h3 className="mb-2 mt-4">Transactions</h3>
					<div className="pagination-controls">
						<label className="form-label mt-1">Limit:</label>
						<div className="ml-2">
							<Form.Control
								as="select"
								value={selectedLimit}
								className="form-control-sm"
								onChange={handleLimitChange}
							>
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
							</Form.Control>
						</div>
					</div>
					<table className="table table-bordered">
						<thead>
							<tr>
								<th scope="col">Transaction ID</th>
								<th scope="col">NIUM Transaction ID</th>
								<th scope="col">Company Name</th>
								<th scope="col">Counterparty</th>
								<th scope="col">Amount</th>
								<th scope="col">Currency</th>
								<th scope="col">Created Date</th>
								<th scope="col">Status</th>
								<th scope="col"></th>
							</tr>
						</thead>
						<tbody>
							{transactions.map(transaction => (
								<tr key={transaction.oskar_id}>
									<td>{transaction.oskar_id}</td>
									<td>{transaction.nium_id}</td>
									<td>{transaction.oskar_transaction.companyName}</td>
									<td>{transaction.oskar_transaction.beneficiaryName}</td>
									<td>{transaction.oskar_transaction.amount}</td>
									<td>{transaction.oskar_transaction.currency.id}</td>
									<td>{transaction.oskar_transaction.createDate}</td>
									<td className={`status-${transaction.compliance_status.toLowerCase()}`}>
										{transaction.compliance_status}
									</td>
									<td>
										<button
											type="button"
											disabled={
												transaction.compliance_status === ComplianceStatusTypes.SENT_TO_BANK
											}
											className="btn btn-sm btn-primary"
											key={"generateLinkButton-" + transaction.oskar_id}
											onClick={() => handleEditClick(transaction.oskar_id)}
										>
											Edit
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<div className="pagination-container">
						<Pagination>
							<Pagination.First onClick={() => setPage(1)} disabled={currentPage === 1} />
							<Pagination.Prev onClick={() => setPage(currentPage - 1)} disabled={currentPage === 1} />

							{Array.from({ length: totalPages }, (_, index) => (
								<Pagination.Item
									key={index + 1}
									active={currentPage === index + 1}
									onClick={() => setPage(index + 1)}
								>
									{index + 1}
								</Pagination.Item>
							))}

							<Pagination.Next
								onClick={() => setPage(currentPage + 1)}
								disabled={currentPage === totalPages}
							/>
							<Pagination.Last
								onClick={() => setPage(totalPages)}
								disabled={currentPage === totalPages}
							/>
						</Pagination>
					</div>
					<Modal show={showModal} onHide={handleCloseModal}>
						<Modal.Header closeButton>
							<Modal.Title>Update Transaction</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							{selectedTransaction &&
								selectedTransaction.nium_transaction.rfiDetails.map(niumTransaction => (
									<DynamicForm
										key={niumTransaction.rfiHashId}
										formData={niumTransaction}
										onFormChange={setFormState}
										onFormSubmit={handleFormSubmit}
									/>
								))}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleCloseModal}>
								Close
							</Button>
							<Button
								variant="primary"
								onClick={() =>
									handleFormSubmit(
										{
											responseToTransactionRFI: { rfiResponseRequest: formState },
											authCode: selectedTransaction.nium_id,
										},
										selectedTransaction._id
									)
								}
							>
								Save
							</Button>
						</Modal.Footer>
					</Modal>
				</div>
			)}
		</div>
	);
});

export default TransactionComplianceTable;
