import { IFrame } from "../../components";
import { StatisticsLayout } from "../../layouts";

export const StatisticsCharts = () => {
	return (
		<StatisticsLayout>
			<IFrame
				style={{ height: "2024px" }}
				url="https://charts.mongodb.com/charts-payset_test-ejxsw/embed/dashboards?id=6267a9c1-98d7-479e-871f-379d082a3ff0&theme=light&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=false&scalingWidth=scale&scalingHeight=scale"
			/>
		</StatisticsLayout>
	);
};
