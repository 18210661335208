import { MongoCharts } from "../../components";
import { StatisticsLayout } from "../../layouts";

export const StatisticsTransactionFees = () => {
	return (
		<StatisticsLayout>
			<MongoCharts
				hasExport
				csvTitle="Fees"
				charts={[
					{ style: { height: "524px" }, charts: ["63f8df6f-045a-4062-8d4c-514e7dae909f"] },
					{ style: { height: "524px" }, charts: ["16717804-4641-407f-ad63-946a29775133"] },
					{ style: { height: "524px" }, charts: ["1a6a74e5-20d1-4792-b16e-1aa9aaeaa3e9"] },
					{ style: { height: "524px" }, charts: ["2de32fb6-11d2-4147-93ab-6fc7ae96563d"] },
					{ style: { height: "524px" }, charts: ["9cf2b4ea-f257-4d8a-b431-8632d6410595"] },
					{ style: { height: "524px" }, charts: ["22ee568a-bbf7-406f-b5e9-183a996f459c"] },
					{ style: { height: "824px" }, charts: ["d4b5ac34-60f2-4a54-809f-c42e6def628b"] },
				]}
				requestDate={"data.valueDate"}
			/>
		</StatisticsLayout>
	);
};
