import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";

import { Component } from "react";
import { Spinner } from "./Spinner";
import DatePicker from "./DatePicker";
import { Button } from "rsuite";
/**
 * @typedef MongoDataExport
 * @type {object}
 * @property {Record<string,string>} fields - The keys valeu pairs for the current the internal keys, and data labels  .
 * @property {Record<keyof fields,any>} values - Key value for the data, the key is the field key and the `value` is the value of the data row.
 */

class MongoCharts extends Component {
	minDate = new Date("2022-01-01");
	maxDate = new Date();

	constructor(props) {
		super(props);
		this.state = {
			charts: props.charts || [],
			loading: true,
			showDatepicker: !props.hideDatepicker,
			style: {
				height: "100%",
				overflow: "hidden",
				background: "rgb(241, 245, 244)",
				boxShadow: "rgba(70, 76, 79, 0.2) 0px 2px 10px 0px",
			},
			timeout: null,
			startDate: this.minDate,
			endDate: this.maxDate,
			requestDate: props.requestDate || "request.createDate",
			hasExport: props.hasExport,
			csvTitle: props.csvTitle || "stats-chart",
		};
	}

	async componentDidMount() {
		await this.renderCharts();
	}

	componentWillUnmount() {
		this.clearTimeout();
	}

	async downloadCsvData(csvData, csvTitle) {
		try {
			const date = new Date();
			const blob = new Blob([csvData], { type: "text/csv" });

			// Create a temporary anchor element
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.style.display = "none";
			a.href = url;
			a.download = `${csvTitle}-${date.getFullYear()}-${date.getMonth()}-${date.getDate()}.csv`;
			document.body.appendChild(a);

			// Initiate file download
			a.click();

			// Clean up
			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
		} catch (error) {
			console.error("Error downloading CSV:", error);
		}
	}
	/**
	 *
	 * @param {MongoCharts} data
	 * @returns {string}
	 */
	convertMongoDataToCsvData(data) {
		const { fields, documents } = data;
		let csvHeaders = [];
		let headerOrder = [];
		Object.entries(fields).forEach(([key, value]) => {
			csvHeaders.push(value);
			headerOrder.push(key);
		});
		const csvBody = documents.reduce((acc, cur) => {
			return [
				...acc,
				headerOrder.map(headerKey => {
					if (typeof cur[headerKey] === "string") {
						return cur[headerKey].replace(",", " ");
					}
					return cur[headerKey];
				}),
			];
		}, []);
		return [csvHeaders, ...csvBody]
			.map(row => {
				return row.join(",");
			})
			.join("\n");
	}

	async renderCharts(filter) {
		let charts = {};
		this.showSpinner();
		const sdk = new ChartsEmbedSDK({
			baseUrl: "https://charts.mongodb.com/charts-payset_test-ejxsw",
		});
		let promises = [];
		this.state.charts.forEach(chartGroup => {
			chartGroup.charts.forEach(async chartId => {
				charts[chartId] = sdk.createChart({
					chartId,
					filter,
				});
				promises.push(await charts[chartId].render(document.getElementById(chartId)));
				if (this.state.hasExport) {
					promises.push(
						document.getElementById(`${chartId}-export-csv`).addEventListener("click", async () => {
							/** @type {MongoDataExport} */
							const data = await charts[chartId].getData();
							const csvData = this.convertMongoDataToCsvData(data);
							await this.downloadCsvData(csvData, this.state.csvTitle);
						})
					);
				}
			});
		});
		await Promise.all(promises).then(() => {
			this.hideSpinner();
		});
	}

	clearTimeout = () => {
		if (this.state.timeout) {
			clearTimeout(this.state.timeout);
			this.setState({ timeout: null });
		}
	};

	hideSpinner = () => {
		let timeout = setTimeout(() => {
			this.clearTimeout();
			this.setState({
				loading: false,
			});
			document.body.classList.remove("modal-open");
		}, 10);
		this.setState({ timeout });
	};

	showSpinner = () => {
		this.setState({
			loading: true,
		});
		document.body.classList.add("modal-open");
	};

	onDateRangeChange = async (startDate, endDate) => {
		this.setState({
			startDate,
			endDate,
		});
		let requestDateInfo = this.state.requestDate;
		let filterData = {
			[requestDateInfo]: {
				$gte: startDate,
				$lte: endDate,
			},
		};
		await this.renderCharts(filterData);
	};

	render() {
		return (
			<>
				{this.state.loading ? (
					<Spinner />
				) : (
					this.state.showDatepicker && (
						<div className="row">
							<div className="col m-3">
								<div className="d-flex justify-content-end">
									<div className="p-2 bd-highlight">
										<DatePicker
											startDate={this.state.startDate}
											endDate={this.state.endDate}
											onChange={this.onDateRangeChange}
											minDate={this.minDate}
											maxDate={this.maxDate}
										></DatePicker>
									</div>
								</div>
							</div>
						</div>
					)
				)}
				<div className={`${this.state.loading ? "mt-5" : ""} p-3`}>
					{this.state.charts &&
						this.state.charts.map((chartGroups, index) => (
							<div
								className="mb-3"
								key={`chartGroup-${index}`}
								style={{
									display: "flex",
									flexDirection: "column",
									...this.state.style,
									...(chartGroups.style || {}),
								}}
							>
								{chartGroups.charts.map(chartId => (
									<>
										<div
											id={chartId}
											key={chartId}
											className={`${this.state.loading && "hidden"} col m-2 p-0 rounded`}
											style={{ flex: 9 }}
										></div>
										{this.state.hasExport && (
											<div style={{ flex: 1 }}>
												<Button
													appearance="default"
													style={{ margin: "0px 8px", border: "1px solid gray" }}
													id={`${chartId}-export-csv`}
												>
													Export CSV
												</Button>
											</div>
										)}
									</>
								))}
							</div>
						))}
				</div>
			</>
		);
	}
}

export { MongoCharts };
