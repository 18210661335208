import { MongoCharts } from "../../components";
import { StatisticsLayout } from "../../layouts";

export const StatisticsNumberOfTransactionsPerBankCountry = () => {
	return (
		<StatisticsLayout>
			<MongoCharts
				hasExport
				csvTitle="Statistics Number Of Transactions Per Bank Country"
				charts={[
					{ style: { height: "624px" }, charts: ["6363b2fd-6434-49d4-84f4-9c6324c0b90d"] },
					{ style: { height: "1024px" }, charts: ["c4add550-2111-4499-b47c-3d35f64c2160"] },
				]}
			/>
		</StatisticsLayout>
	);
};
