import { useRef } from "react";
import { MainLayout } from "../layouts";
import TransactionComplianceTable from '../components/TransactionComplianceTable';


export const TransactionCompliance = () => {
    const childRef = useRef(null);
	
	return (
		<MainLayout>
            <div className='d-flex flex-column flex-center text-center p-10'>
                <div className='mb-0'>
                    <TransactionComplianceTable ref={childRef}/>
                </div>
            </div>
		</MainLayout>
	)
};
