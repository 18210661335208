import { StatisticsLayout } from "../../layouts";
import { MongoCharts } from "../../components";

export const StatisticsNumberOfTransactionsPerStatus = () => {
	return (
		<StatisticsLayout>
			<MongoCharts
				charts={[
					{ style: { height: "524px" }, charts: ["63f8dab9-ace8-4940-8863-2aca3504c762"] },
					{ style: { height: "824px" }, charts: ["15e954a4-9b89-4810-9269-1d949ca05a8a"] },
				]}
				requestDate={"request.valueDate"}
			/>
		</StatisticsLayout>
	);
};
