import { Component } from 'react';

class LightLayout extends Component {
  render() {
    return (
      <div className='app-container'>
        <main className='container-fluid pt-4 pb-4'>{this.props.children}</main>
      </div>
    );
  }
}

export { LightLayout };