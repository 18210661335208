import { useEffect, useState } from "react";
import { MainLayout } from "../layouts";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserActions } from "../actions/user.actions";
import { StatisticsBankProviderDetailsNetsuite } from "../pages/statistics/BankProviderDetailsNetsuite";

export const Imports = () => {
	const { user } = useAuth0();

	useEffect(() => { }, []);
	const [message, setMessage] = useState();
	const [showAlert, setShowAlert] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [showUploading, setShowUploading] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const userActions = useUserActions();

	const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const handleFormSubmit = async (event) => {
		event.preventDefault();
		if (selectedFile) {
			setShowUploading(true)
			setShowErrorAlert(false)
			await userActions
				.importBankProviderDetails(selectedFile)
				.then(response => {
					if (response.message) {
						setMessage(response.message);
						setShowUploading(false)
						setShowAlert(true);
					}
				})
				.catch(error => {
					setShowUploading(false)
					setShowErrorAlert(true)
				});
		}
	};

	const toggleShowAlert = () => {
		setShowAlert(!showAlert);
	};

	const toggleShowErrorAlert = () => {
		setShowErrorAlert(!showErrorAlert);
	};


	return (
		<MainLayout>
			<div className="col-md-6 offset-md-3 mt-5">
				<div className="mb-5">
					<h1>Hi {user?.name}!</h1>
				</div>
			</div>
			{showErrorAlert && (
				<div className="row">
					<div className="col-12 mt-3">
						<div className="alert alert-warning alert-dismissible fade show" role="alert">
								Something went wrong try again.
							<button
								type="button"
								className="close"
								data-dismiss="alert"
								aria-label="Close"
								onClick={toggleShowErrorAlert}
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
					</div>
				</div>
			)}
			{showAlert && (
				<div className="row">
					<div className="col-12 mt-3">
						<div className="alert alert-success alert-dismissible fade show" role="alert">
							{message}.
							<button
								type="button"
								className="close"
								data-dismiss="alert"
								aria-label="Close"
								onClick={toggleShowAlert}
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
					</div>
				</div>
			)}
			{showUploading && (
				<div className="row">
					<div className="col-12 mt-3">
						<div className="alert alert-success alert-dismissible fade show" role="alert">
							Uploading....
						</div>
					</div>
				</div>
			)}
			{!showAlert && !showUploading && !showErrorAlert && (
				<div>
					<div className="row">
						<div className="col-12">
							<form className="form-inline" onSubmit={handleFormSubmit}>
								<div className="form-group">
									<label for="csvFile">Upload CSV file:</label>
									<input type="file" id="csvFile" className="form-control" accept=".csv, .xlsx" required onChange={handleFileChange} />
								</div>
								<div className="form-group">
									<button type="submit" id="uploadFile" className="btn btn-primary">Upload & Parse</button>
								</div>
							</form>
						</div>
					</div>
					<div className="row">
						<div className="col-12"></div>
						<div className="col-12 mt-3">
							<StatisticsBankProviderDetailsNetsuite></StatisticsBankProviderDetailsNetsuite>
						</div>
					</div>
				</div>
			)}
		</MainLayout>
	)
};
