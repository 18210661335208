import { StatisticsLayout } from "../../layouts";
import { MongoCharts } from "../../components";

export const StatisticsBankProviderAccountsBalances = () => {
	return (
		<StatisticsLayout>
			<MongoCharts
				charts={[
					{ style: { height: "524px" }, charts: ["63f8c2f1-da34-445a-813a-4915c2b9e7d6"] },
					{ style: { height: "824px" }, charts: ["48c8be27-10d5-479b-9ee9-de8644bbff0a"] },
				]}
				requestDate={"createdAt"}
			/>
		</StatisticsLayout>
	);
};
