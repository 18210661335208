import axios from "axios";

const httpClient = axios.create();
export { useAxiosWrapper };

// adds access tokens in all api requests
// this interceptor is only added when the auth0 instance is ready and exports the getAccessTokenSilently method
export const addAccessTokenInterceptor = getAccessTokenSilently => {
	httpClient.interceptors.request.use(async config => {
		const token = await getAccessTokenSilently();
		config.headers.Authorization = `Bearer ${token}`;
		return config;
	});
};
function useAxiosWrapper() {
	return {
		get: request("get"),
		post: request("post"),
		put: request("put"),
		delete: request("delete"),
		upload: upload(),
	};

	function request(method) {
		return async (url, body) => {
			console.log("request", url, body);
			const requestOptions = {
				method,
			};
			if (body) {
				requestOptions.headers = {};
				requestOptions.headers["Content-Type"] = "application/json";
				requestOptions.data = body;
			}
			return httpClient({ url, method, ...requestOptions }).then(handleResponse);
		};
	}

	function upload() {
		const method = "post";
		return async (url, file) => {
			const data = new FormData();
			data.append("file", file);
			return httpClient({ url, method, data }).then(handleResponse);
		};
	}

	function handleResponse(response) {
		if (!response.data) {
			return Promise.reject(response.data);
		}
		return response.data;
	}
}
