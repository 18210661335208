import { Component } from "react";
import { Spinner } from "./Spinner";
import DatePicker from "./DatePicker";
import moment from "moment";

class IFrame extends Component {
	constructor(props) {
		super(props);
		this.state = {
			originalUrl: props.url,
			url: props.url,
			loading: true,
			style: { width: "100%", height: "100%", overflow: "hidden", ...(this.props.style || {}) },
			timeout: null,
		};
		document.body.classList.add("modal-open");
	}

	componentWillUnmount() {
		clearTimeout();
	}

	clearTimeout = () => {
		if (this.state.timeout) {
			clearTimeout(this.state.timeout);
			this.setState({ timeout: null });
		}
	};

	hideSpinner = () => {
		let timeout = setTimeout(
			() => {
				clearTimeout();
				this.setState({
					loading: false,
				});
				document.body.classList.remove("modal-open");
			},
			this.state.url.includes("http") ? 2000 : 10
		);
		this.setState({ timeout });
	};

	onDateRangeChange = (startDate, endDate) => {
		const start = moment(startDate).format("YYYY-MM-DD");
		const end = moment(endDate).format("YYYY-MM-DD");
		this.setState({ url: this.state.originalUrl + "&startDate=" + start + "&endDate=" + end });
	};

	render() {
		return (
			<>
				{this.state.loading ? (
					<Spinner />
				) : (
					<div className="row">
						<div className="col m-3">
							<div className="d-flex justify-content-end">
								<div className="p-2 bd-highlight">
									<DatePicker
										startDate={new Date("2022-01-01")}
										endDate={new Date()}
										onChange={this.onDateRangeChange}
									></DatePicker>
								</div>
							</div>
						</div>
					</div>
				)}
				<iframe
					title="Statistics"
					scrolling="no"
					frameBorder="0"
					onLoad={this.hideSpinner}
					className={`${this.state.loading && "hidden"}`}
					style={this.state.style}
					src={this.state.url}
				></iframe>
			</>
		);
	}
}

export { IFrame };
