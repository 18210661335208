import { MongoCharts } from "../../components";
import { StatisticsLayout } from "../../layouts";

export const StatisticsNumberAndVolumeOfIOTransactions = () => {
	return (
		<StatisticsLayout>
			<MongoCharts
				charts={[
					{ style: { height: "524px" }, charts: ["b615d616-7985-4dfb-8dde-9c8d00627115"] },
					{ style: { height: "824px" }, charts: ["63a5b5cc-7dcf-4ca2-87a7-183849718551"] },
				]}
			/>
		</StatisticsLayout>
	);
};
