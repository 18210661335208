import { LightLayout } from "../layouts";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

export default Login;

function Login() {
	const history = useHistory();
	const { isAuthenticated } = useAuth0();
	if (isAuthenticated) {
		history.push("/dashboard");
	}
	return (
		<LightLayout>
			<div className="col-md-6 offset-md-3 mt-5">
				<div className="card">
					<h4 className="card-header text-center">Login Payset Dashboard</h4>
					<div className="card-body text-center">
						<a className="btn btn-primary" href="/dashboard">
							Login
						</a>
					</div>
				</div>
			</div>
		</LightLayout>
	);
}
