import { useLocation } from "react-router-dom";
import { history } from "../helpers/history";

function StatisticsNav() {
	const location = useLocation();

	const tabs = [
		{ label: "Main", path: "/stats" },
		{ label: "Number of transactions per bank country", path: "/stats/number-of-transactions-per-bank-country" },
		{ label: "Volume of transactions per bank country", path: "/stats/volume-of-transactions-per-bank-country" },
		{
			label: "Number of incoming/outgoing transactions per bank",
			path: "/stats/number-of-io-transactions-per-bank",
		},
		{ label: "Stats incoming/outgoing", path: "/stats/number-and-volume-of-io-transactions" },
		{ label: "Fees", path: "/stats/fees" },
		{ label: "Number of transactions per status", path: "/stats/number-of-transactions-per-status" },
		{ label: "Balance main account", path: "/stats/balance-main-account" },
		{ label: "Bank provider accounts balances", path: "/stats/bank-provider-accounts-balances" },
		{ label: "Bank provider details", path: "/stats/bank-provider-details" },
	].map(tab => {
		tab.active = tab.path === location.pathname;
		return tab;
	});

	function openTab(e, tab) {
		history.push(tab.path);
	}

	return (
		<div className="text-center">
			{tabs &&
				tabs.map((tab, index) => (
					<button
						key={"button-" + index}
						className={`m-1 btn ${tab.active && "btn-success active"} ${!tab.active && "btn-info"}`}
						onClick={e => openTab(e, tab)}
					>
						{tab.label}
					</button>
				))}
		</div>
	);
}

export { StatisticsNav };
