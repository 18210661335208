import { useRef, useState } from 'react';
import { useUserActions } from '../actions/user.actions';
import { MainLayout } from '../layouts';
import AgentsTable from '../components/AgentsTable';

export const Agents = () => {
  const [message, setMessage] = useState();
  const userActions = useUserActions();
  const [showAlert, setShowAlert] = useState(false);
  const [syncingAgents, setSyncingAgents] = useState(false);
  const childRef = useRef(null);

  const syncAgents = () => {
    if (syncingAgents) {
      return;
    }
    setSyncingAgents(true);
    userActions
      .syncAgents()
      .then(response => {
        setMessage(response.message);
        if (response.message) {
          setShowAlert(true);
        }
        setSyncingAgents(false);
        childRef.current.refreshAgents();
      })
      .catch(error => {
        console.log(error);
        setSyncingAgents(false);
      });
  };

  const toggleShowAlert = () => {
    setShowAlert(!showAlert);
  };

  return (
    <MainLayout>
      <div className='d-flex flex-column flex-center text-center p-10'>
        {showAlert && (
          <div className='alert alert-success alert-dismissible fade show' role='alert'>
            <strong>Holy guacamole!</strong> {message}!
            <button
              type='button'
              className='close'
              data-dismiss='alert'
              aria-label='Close'
              onClick={toggleShowAlert}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
        )}
        <div className='mr-auto mt-1 d-flex mb-7'>
          <div className='ml-2 mr-3'>
            <button disabled={syncingAgents} onClick={syncAgents} className='btn btn-md btn-primary'>
              <span className='spinner-border spinner-border-sm mr-2'
                    style={{ display: syncingAgents ? 'inline-block' : 'none' }} role='status'
                    aria-hidden='true'></span>
              Sync Agents
            </button>
          </div>
          <div className='fw-semibold fs-6 mt-2 text-gray-500'>This button is going to sync the oskar agents!</div>
        </div>
        <div className='mb-0'>
          <AgentsTable ref={childRef}/>
        </div>
      </div>

    </MainLayout>
  );
};
