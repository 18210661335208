import { useAxiosWrapper } from "../helpers/axiosWrapper";
import { convertFilesToBase64 } from "../helpers/base64utils";

export { useUserActions };

function useUserActions() {
	const syncCustomersUrl = `${process.env.REACT_APP_CUSTOMER_SYNC_URL}`;
	const syncCurrenciesUrl = `${process.env.REACT_APP_CURRENCIES_SYNC_URL}`;
	const syncCountriesUrl = `${process.env.REACT_APP_COUNTRIES_SYNC_URL}`;
	const syncAgentsUrl = `${process.env.REACT_APP_AGENTS_SYNC_URL}`;
	const getAgentsUrl = `${process.env.REACT_APP_AGENTS_LIST_URL}`;
	const generateReportsUrl = `${process.env.REACT_APP_GENERATE_REPORTS_URL}`;
	const backofficeUrl = `${process.env.REACT_APP_BACKOFFICE_API}`;
	const importBankProviderDetailsUrl = `${process.env.REACT_APP_NETSUITE_URL}`;
	const complianceTransactionsUrl = `${process.env.REACT_APP_NIUM_COMPLIANCE_TRANSCTIONS_URL}`;

	const axiosWrapper = useAxiosWrapper();

	return {
		syncCustomers,
		syncCurrencies,
		syncCountries,
		syncAgents,
		getAgents,
		getTransactions,
		generateUsersReport,
		generateTransactionsReport,
		generateDailyReport,
		verifyClientAuthBackoffice,
		importBankProviderDetails,
		generateBankTransactionsReport,
		updateTransactionCompliance,
		generateBankBalancesReport
	};

	async function syncCustomers() {
		return await axiosWrapper.get(`${syncCustomersUrl}`);
	}

	async function syncCurrencies() {
		return await axiosWrapper.get(`${syncCurrenciesUrl}`);
	}

	async function syncCountries() {
		return await axiosWrapper.get(`${syncCountriesUrl}`);
	}

	async function syncAgents() {
		return await axiosWrapper.get(`${syncAgentsUrl}`);
	}

	async function getAgents(limit, page) {
		return await axiosWrapper.get(`${getAgentsUrl}?limit=${limit}&page=${page}`);
	}

	async function getTransactions(limit, page) {
		return await axiosWrapper.get(`${complianceTransactionsUrl}/list?limit=${limit}&page=${page}`);
	}

	async function updateTransactionCompliance(data, id) {
		try {
			const rfiResponses = data.responseToTransactionRFI.rfiResponseRequest;
			for (let i = 0; i < rfiResponses.length; i++) {
				if (rfiResponses[i].rfiResponseInfo && rfiResponses[i].rfiResponseInfo.identificationDoc) {
					const files = rfiResponses[i].rfiResponseInfo.identificationDoc.identificationDocument;
					const convertedFiles = await convertFilesToBase64(files);
					rfiResponses[i].rfiResponseInfo.identificationDoc.identificationDocument = convertedFiles;
				}
			}
			const response = await axiosWrapper.post(`${complianceTransactionsUrl}/update/${id}`, data);
			return response;
		} catch (error) {
			throw error;
		}
	}

	async function verifyClientAuthBackoffice() {
		return await axiosWrapper.get(`${backofficeUrl}`);
	}

	async function generateUsersReport() {
		return await axiosWrapper.post(generateReportsUrl);
	}

	async function generateTransactionsReport(startDate, endDate) {
		let url = new URL(generateReportsUrl + "/api/v1/transactions/report");
		url.searchParams.append("startDate", startDate.toISOString().substring(0, 10));
		url.searchParams.append("endDate", endDate.toISOString().substring(0, 10));
		return await axiosWrapper.post(url.toString(), {
			startDate: startDate.toISOString().substring(0, 10),
			endDate: endDate.toISOString().substring(0, 10),
		});
	}

	async function generateDailyReport(report) {
		let url = new URL(generateReportsUrl + `/api/v1/transactions/report/${report}`);
		return await axiosWrapper.post(url.toString(), false);
	}

	async function generateBankTransactionsReport(bank, startDate, endDate) {
		let url = new URL(generateReportsUrl + "/api/v1/banks/report");
		url.searchParams.append("bank", bank);
		url.searchParams.append("startDate", startDate.toISOString().substring(0, 10));
		url.searchParams.append("endDate", endDate.toISOString().substring(0, 10));


		return await axiosWrapper.post(url.toString());
	}

	async function generateBankBalancesReport(bank) {
		let url = new URL(generateReportsUrl + "/api/v1/accounts/balance");
		url.searchParams.append("bank", bank);
		return await axiosWrapper.post(url.toString());
	}

	async function generateDailyReport(report) {
		let url = new URL(generateReportsUrl + `/api/v1/transactions/report/${report}`);
		return await axiosWrapper.post(url.toString(), false);
	}

	async function importBankProviderDetails(file) {
		let url = new URL(importBankProviderDetailsUrl + "/import");
		return await axiosWrapper.upload(url.toString(), file);
	}
}
