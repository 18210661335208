import { Component } from 'react';
import { Nav } from '../components';
import { StatisticsNav } from '../components';

class StatisticsLayout extends Component {
  render() {
    return (
      <div className='app-container'>
        <Nav />
        <StatisticsNav />
        <main className='container-fluid pt-1 pb-4'>
          {this.props.children}
        </main>
      </div>
    );
  }
}

export { StatisticsLayout };