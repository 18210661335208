import { MongoCharts } from "../../components";
import { StatisticsLayout } from "../../layouts";

export const StatisticsNumberOfIOTransactionsPerBank = () => {
	return (
		<StatisticsLayout>
			<MongoCharts
				hasExport
				csvTitle="Number of incoming/outgoing transactions per bank"
				charts={[
					{ style: { height: "824px" }, charts: ["d9e02fc1-33ec-40bd-9cfb-80f34e8d4f4e"] },
					{ style: { height: "824px" }, charts: ["6365408c-f835-47d9-8534-0481ca76aa79"] },
				]}
			/>
		</StatisticsLayout>
	);
};
