import { MongoCharts } from "../../components";
import { StatisticsLayout } from "../../layouts";

export const StatisticsVolumeOfTransactionsPerBankCountry = () => {
	return (
		<StatisticsLayout>
			<MongoCharts
				hasExport
				csvTitle="Statistics Volume Of Transactions Per Bank Country"
				charts={[{ style: { height: "2024px" }, charts: ["6365396b-c886-43ed-8f44-d056bf513fc7"] }]}
			/>
		</StatisticsLayout>
	);
};
