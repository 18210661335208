export async function convertFilesToBase64(files) {
	return Promise.all(
		files.map(file => {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onloadend = () => {
					const base64String = reader.result.split(",")[1]; // Split the string and take the second part
					resolve({
						fileName: file.name,
						fileType: file.type,
						document: base64String,
					});
				};
				reader.onerror = reject;
				reader.readAsDataURL(file);
			});
		})
	);
}
