import { MongoCharts } from "../../components";
import { StatisticsLayout } from "../../layouts";

export const StatisticsBalanceMainAccount = () => {
	return (
		<StatisticsLayout>
			<MongoCharts
				charts={[{ style: { height: "2024px" }, charts: ["63f8bc16-ad08-471c-8af4-243a652a021f"] }]}
				requestDate={"data.valueDate"}
			/>
		</StatisticsLayout>
	);
};
