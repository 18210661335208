export function getConfig() {
	const audience =
		process.env.REACT_APP_AUDIENCE && process.env.REACT_APP_AUDIENCE !== "YOUR_API_IDENTIFIER"
			? process.env.REACT_APP_AUDIENCE
			: null;

	return {
		domain: process.env.REACT_APP_DOMAIN,
		clientId: process.env.REACT_APP_CLIENT_ID,
		...(audience ? { audience } : null),
	};
}
