import "bootstrap/dist/css/bootstrap.css";
import "./App.css";

import React, { useEffect } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";

import {
	Agents,
	ComingSoon,
	Dashboard,
	Imports,
	Reports,
	StatisticsBalanceMainAccount,
	StatisticsBankProviderAccountsBalances,
	StatisticsCharts,
	StatisticsNumberAndVolumeOfIOTransactions,
	StatisticsNumberOfIOTransactionsPerBank,
	StatisticsNumberOfTransactionsPerBankCountry,
	StatisticsNumberOfTransactionsPerStatus,
	StatisticsTransactionFees,
	StatisticsVolumeOfTransactionsPerBankCountry,
	StatisticsBankProviderDetailsNetsuite, TransactionCompliance,
} from './pages';
import { history } from "./helpers/history";
import { useAuth0 } from "@auth0/auth0-react";
import Login from "./pages/Login";
import { Spinner } from "./components";

import { addAccessTokenInterceptor } from "./helpers/axiosWrapper";
import { PrivateRoute } from "./PrivateRoute";

export { App };

function App() {
	const { isLoading, error, isAuthenticated, getAccessTokenSilently } = useAuth0();

	useEffect(() => {
		addAccessTokenInterceptor(getAccessTokenSilently);
	}, [getAccessTokenSilently]);

	if (error) {
		return <div>Oops... {error.message}</div>;
	}

	if (isLoading) {
		return <Spinner />;
	}
	return (
		<Router history={history}>
			<Switch>
				<Route exact path="/" component={isAuthenticated ? Dashboard : Login} />
				<PrivateRoute path="/dashboard" component={Dashboard} />
				<PrivateRoute path="/agents" component={Agents} />
				<PrivateRoute
					path="/stats/number-of-transactions-per-bank-country"
					component={StatisticsNumberOfTransactionsPerBankCountry}
				/>
				<PrivateRoute
					path="/stats/volume-of-transactions-per-bank-country"
					component={StatisticsVolumeOfTransactionsPerBankCountry}
				/>
				<PrivateRoute
					path="/stats/number-of-io-transactions-per-bank"
					component={StatisticsNumberOfIOTransactionsPerBank}
				/>
				<PrivateRoute
					path="/stats/number-and-volume-of-io-transactions"
					component={StatisticsNumberAndVolumeOfIOTransactions}
				/>
				<PrivateRoute
					path="/stats/number-of-transactions-per-status"
					component={StatisticsNumberOfTransactionsPerStatus}
				/>
				<PrivateRoute path="/stats/balance-main-account" component={StatisticsBalanceMainAccount} />
				<PrivateRoute
					path="/stats/bank-provider-accounts-balances"
					component={StatisticsBankProviderAccountsBalances}
				/>
				<PrivateRoute
					path="/stats/bank-provider-details"
					component={StatisticsBankProviderDetailsNetsuite}
				/>
				<PrivateRoute path="/stats/fees" component={StatisticsTransactionFees} />
				<PrivateRoute path="/stats" component={StatisticsCharts} />
				<Route path="/not-found" component={ComingSoon} />
				<PrivateRoute path="/reports" component={Reports} />
				<PrivateRoute path="/imports" component={Imports} />
				<PrivateRoute path="/transaction-compliance" component={TransactionCompliance} />
				<Route path="/login" component={Login} />
				<Redirect from="*" to="/" />
			</Switch>
		</Router>
	);
}
