import { LightLayout } from "../layouts";

export { ComingSoon };

function ComingSoon() {
	return (
		<LightLayout>
			<div className="col-md-6 offset-md-3 mt-5">
				<div>
					<h1>Coming Soon ...</h1>
				</div>
			</div>
		</LightLayout>
	);
}
