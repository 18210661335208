import { useAuth0 } from "@auth0/auth0-react";

function Nav() {
	const { isAuthenticated, logout } = useAuth0();

	const logoutWithRedirect = () =>
		logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		});

	if (!isAuthenticated) return null;

	const navItems = [
		{ label: 'Home', link: '/dashboard' },
		{ label: 'Agents', link: '/agents' },
		{ label: 'Statistics', link: '/stats' },
		{ label: 'Reports', link: '/reports' },
		{ label: 'Imports', link: '/imports' },
		{ label: 'Compliance', link: '/transaction-compliance' }
	];

	return (
		<nav className="navbar navbar-expand-lg navbar-light bg-light">
			<a className="navbar-brand" href="/">
				<img src="/logo_payset.png" width="30" height="30" alt="" />
			</a>
			<button
				className="navbar-toggler"
				type="button"
				data-toggle="collapse"
				data-target="#navbarNav"
				aria-controls="navbarNav"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<span className="navbar-toggler-icon" />
			</button>
			<div className="collapse navbar-collapse" id="navbarNav">
				<ul className="navbar-nav">
					{navItems.map((item, index) => (
						<li className="nav-item" key={index}>
							<a className="nav-link" href={item.link}>
								{item.label} <span className="sr-only">(current)</span>
							</a>
						</li>
					))}
				</ul>
				<ul className="navbar-nav ml-auto">
					<li className="nav-item">
						<button onClick={logoutWithRedirect} className="btn btn-primary">
							Logout
						</button>
					</li>
				</ul>
			</div>
		</nav>
	);
}

export { Nav };
