import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Spinner } from "./components";

const PrivateRoute = ({ component, ...args }) => (
	<Route
		component={withAuthenticationRequired(component, {
			onRedirecting: () => <Spinner />,
		})}
		{...args}
	/>
);

export { PrivateRoute };
