import { MongoCharts } from "../../components";
const bankProvidersCharts = `${process.env.REACT_APP_BANK_PROVIDER_CHARTS}`;

export const StatisticsBankProviderDetailsNetsuite = () => {
  return (
      <MongoCharts
        charts={[{ style: { height: "2024px" }, charts: [bankProvidersCharts] }]} 
        hideDatepicker = 'true'
      />
  );
};
