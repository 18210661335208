import React, { useEffect, useState } from "react";
import { useUserActions } from "../actions/user.actions";
import { MainLayout } from "../layouts";
import DatePicker from "../components/DatePicker";
import { useAuth0 } from "@auth0/auth0-react";
import { Form } from 'react-bootstrap';

export const Reports = () => {
	const { user } = useAuth0();
	const minDate = new Date("2022-01-01");
	const maxDate = new Date();
	let monthsDate = new Date();
	monthsDate.setMonth(monthsDate.getMonth());
	monthsDate.setDate(1);
	const [startDate, setStartDate] = useState(monthsDate);
	const [endDate, setEndDate] = useState(new Date());
	const [bankStartDate, setBankStartDate] = useState(monthsDate);
	const [bankEndDate, setBankEndDate] = useState(new Date());
	const [message, setMessage] = useState();
	const [isWarningMessage, setIsWarningMessage] = useState(false);
	const userActions = useUserActions();
	const [showAlert, setShowAlert] = useState(false);
	const [generatingReports, setGeneratingReports] = useState(false);
	const [generatingTransactionReports, setGeneratingTransactionReports] = useState(false);
	const [generateBankBalancesReport, setGenerateBankBalancesReport] = useState(false);
	
	const [generatingDailyReport, setGeneratingDailyReport] = useState(false);
	const [generatingUserReports, setGeneratingUserReports] = useState(false);
	const [selectedDailyReportType, setSelectedDailyReportType] = useState("all-accounts");	
	const banks = [
		{
			label: "Equals Money",
			value: "em",
		},
		{
			label: "Currency Cloud",
			value: "cc",
		},
		{
			label: "Banking Circle",
			value: "bc",
		},
		{
			label: "Clear Junction",
			value: "cj",
		},
		{
			label: "Safeguarding",
			value: "sg",
		}
	]
	const [selectedBank, setSelectedBank] = useState(banks[0].value);
	const [selectedBankBalance, setSelectedBankBalance] = useState(banks[0].value);


	const dailyReportTypes = [
		{
			label: "All Accounts",
			value: "all-accounts",
		},
		{
			label: "Incoming Transactions",
			value: "incoming-transactions",
		},
		{
			label: "Main Account",
			value: "main-account",
		},
	]

	useEffect(() => {}, []);

	function onDateRangeChange(startDate, endDate) {
		setStartDate(startDate);
		setEndDate(endDate);
	}

	function onBankDateRangeChange(startDate, endDate) {
		setBankStartDate(startDate);
		setBankEndDate(endDate);
	}

	function verifyBackOfficeApi() {
		userActions
			.verifyClientAuthBackoffice()
			.then(response => {
				if (response.message) {
					console.log(response);
				}
			})
			.catch(error => {
				console.log(error);
				setMessage(error.message);
				setShowAlert(true);
			});
	}

	function generateUsersReport() {
		setGeneratingReports(true);
		setGeneratingUserReports(true);
		userActions
			.generateUsersReport()
			.then(response => {
				if (response.message) {
					setMessage(response.message);
					setShowAlert(true);
				}
			})
			.catch(error => {
				console.log(error);
				// setError("apiError", { message: error });
			})
			.finally(() => {
				setGeneratingReports(false);
				setGeneratingUserReports(false);
			});
	}

	function generateDailyReport() {
		setGeneratingReports(true);
		setGeneratingDailyReport(true);
		userActions
			.generateDailyReport(selectedDailyReportType)
			.then(response => {
				if (response.message) {
					setMessage(response.message);
					setShowAlert(true);
				}
			})
			.catch(error => {
				console.log(error);
				// setError("apiError", { message: error });
			})
			.finally(() => {
				setGeneratingReports(false);
				setGeneratingDailyReport(false);
			});
	}

	function generateTransactionsReport() {
		setGeneratingReports(true);
		setGeneratingTransactionReports(true);
		setIsWarningMessage(false)
		userActions
			.generateTransactionsReport(startDate, endDate)
			.then(response => {
				if (response.message) {
					setMessage(response.message);
					setShowAlert(true);
				}
			})
			.catch(error => {
				if (error.response && error.response.data && error.response.data.message) {
					setIsWarningMessage(true)
					setMessage(error.response.data.message);
					setShowAlert(true);
				}
			})
			.finally(() => {
				setGeneratingReports(false);
				setGeneratingUserReports(false);
				setGeneratingTransactionReports(false);
			});
	}

	function generateBankTransactionsReport() {
		setGeneratingReports(true);
		setGeneratingTransactionReports(true);
		setIsWarningMessage(false)
		userActions
			.generateBankTransactionsReport(selectedBank, bankStartDate, bankEndDate)
			.then(response => {
				if (response.message) {
					if(response.statusCode !== 200){
						setIsWarningMessage(true)
					}
					setMessage(response.message);
					setShowAlert(true);
				}
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				setGeneratingReports(false);
				setGeneratingUserReports(false);
				setGeneratingTransactionReports(false);
			});
	}


	function generateBankAccountBalancesReport() {
		setGeneratingReports(true);
		setGenerateBankBalancesReport(true);
		setIsWarningMessage(false)
		userActions
			.generateBankBalancesReport(selectedBankBalance)
			.then(response => {
				if (response.message) {
					setMessage(response.message);
					setShowAlert(true);
				}
			})
			.catch(error => {
				if (error.response && error.response.data && error.response.data.message) {
					setIsWarningMessage(true)
					setMessage(error.response.data.message);
					setShowAlert(true);
				}
			})
			.finally(() => {
				setGeneratingReports(false);
				setGeneratingUserReports(false);
				setGenerateBankBalancesReport(false);
			});
	}

	const handleDailyReportTypeChange = (event) => {
		setSelectedDailyReportType(event.target.value);
	};

	const handleBankChange = (event) => {
		setSelectedBank(event.target.value);
	};

	const handleBankBalanceChange = (event) => {
		setSelectedBankBalance(event.target.value);
	};


	const toggleShowAlert = () => {
		setShowAlert(!showAlert);
	};

	return (
		<MainLayout>
			<div className="col-md-6 offset-md-3 mt-5">
				<div className="mb-5">
					<h1>Hi {user?.name}!</h1>
					<p>You can generate reports using the buttons bellow!</p>
				</div>

				{showAlert && (
					<div className="row">
						<div className="col-12 mt-3">
							<div className="alert alert-success alert-dismissible fade show" role="alert">
								{message}. 
								{!isWarningMessage && (
								<> 
									{" "}Please check the Slack{" "}
									<a href="https://payset.slack.com/channels/reports" target="_blank" rel="noreferrer">
									#reports
									</a>{" "}
									channel
								</>
								)}
								<button
									type="button"
									className="close"
									data-dismiss="alert"
									aria-label="Close"
									onClick={toggleShowAlert}
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
						</div>
					</div>
				)}


				{!showAlert && (
					<div className="row">
						<div className="col-12 mt-3">
							<DatePicker
								startDate={startDate}
								endDate={endDate}
								onChange={onDateRangeChange}
								minDate={minDate}
								maxDate={maxDate}
							></DatePicker>
							<button
								disabled={generatingReports}
								onClick={generateTransactionsReport}
								className="btn btn-primary ml-1"
							>
								<span className="spinner-border spinner-border-sm mr-2"
											style={{ display: generatingTransactionReports ? "inline-block" : "none" }} role="status"
											aria-hidden="true"></span>
								Generate Transactions Report
							</button>
						</div>
					</div>
				)}
				{!showAlert && (
					<div className="row mt-5">
						<div className="col-12 mt-3">
							<button
								disabled={generatingReports}
								onClick={generateUsersReport}
								className="btn btn-primary ml-1"
							>
								<span className="spinner-border spinner-border-sm mr-2"
											style={{ display: generatingUserReports ? "inline-block" : "none" }} role="status"
											aria-hidden="true"></span>
								Generate Users Report
							</button>
						</div>
					</div>
				)}
				{!showAlert && (
					<div className="row mt-5">
						<div className="col-12 mt-3">
							<div className="form-group form-inline">
							<Form.Control
								as="select" value={selectedDailyReportType} onChange={handleDailyReportTypeChange}>
								{dailyReportTypes.map((option) => (
									<option value={option.value} key={`daily-report-${option.value}`}>{option.label}</option>
								))}
							</Form.Control>

							<button
								disabled={generatingReports}
								onClick={generateDailyReport}
								className="btn btn-primary ml-1"
							>
								<span className="spinner-border spinner-border-sm mr-2"
											style={{ display: generatingDailyReport ? "inline-block" : "none" }} role="status"
											aria-hidden="true"></span>
								Generate Daily Report
							</button>
							</div>
						</div>
					</div>
				)}
				{!showAlert && (
					<div className="row">
						<div className="col-12 mt-3">
							<div className="form-group form-inline">
								<DatePicker
									startDate={bankStartDate}
									endDate={bankEndDate}
									onChange={onBankDateRangeChange}
									minDate={minDate}
									maxDate={maxDate}></DatePicker>
								&nbsp;&nbsp;
								<Form.Control
									as="select" value={selectedBank} onChange={handleBankChange}>
									{banks.map((option) => (
										<option value={option.value} key={`daily-report-${option.value}`}>{option.label}</option>
									))}
								</Form.Control>

								<button
									disabled={generatingReports}
									onClick={generateBankTransactionsReport}
									className="btn btn-primary ml-1"
								>
									<span className="spinner-border spinner-border-sm mr-2"
												style={{ display: generatingTransactionReports ? "inline-block" : "none" }} role="status"
												aria-hidden="true"></span>
									Get Bank Transactions Report
								</button>
							</div>
						</div>
					</div>
				)}
 
			 {!showAlert && (
					<div className="row">
						<div className="col-12 mt-3">
							<div className="form-group form-inline">
								<Form.Control
									as="select" value={selectedBankBalance} onChange={handleBankBalanceChange}>
									{banks.map((option) => (
										<option value={option.value} key={`daily-report-${option.value}`}>{option.label}</option>
									))}
								</Form.Control>

								<button
									disabled={generatingReports}
									onClick={generateBankAccountBalancesReport}
									className="btn btn-primary ml-1"
								>
									<span className="spinner-border spinner-border-sm mr-2"
												style={{ display: generateBankBalancesReport ? "inline-block" : "none" }} role="status"
												aria-hidden="true"></span>
									Get Account Balances Report
								</button>
							</div>
						</div>
					</div>
				)} 


				<div className="row mt-5">
					<div className="col-12 mt-3">
						<button onClick={verifyBackOfficeApi} className="btn btn-primary ml-1">
							Verify BackOffice API
						</button>
					</div>
				</div>
			</div>
		</MainLayout>
	);
};
