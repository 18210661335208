import React, { useState } from "react";

import { DateRangePicker } from "rsuite";
import moment from "moment";

const { allowedRange } = DateRangePicker;

export default function DatePicker({ startDate, endDate, onChange, minDate, maxDate }) {
	const dateFormat = "dd-MM-yyyy";
	const [dateRange, setDateRange] = useState([moment(startDate).toDate(), moment(endDate).toDate()]);
	const handleChange = value => {
		setDateRange(value);
		if (value === null) {
			value = [minDate, maxDate];
		}
		if (Array.isArray(value) && value.length === 2) {
			onChange(value[0], value[1]);
		}
	};
	return (
		<DateRangePicker
			placeholder="Select Date Range"
			defaultValue={dateRange}
			onChange={handleChange}
			format={dateFormat}
			placement={"bottomEnd"}
			disabledDate={allowedRange(minDate, maxDate)}
		/>
	);
}
