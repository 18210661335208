import { useEffect, useState } from "react";
import { useUserActions } from "../actions/user.actions";
import { MainLayout } from "../layouts";
import { useAuth0 } from "@auth0/auth0-react";
import welcomeIcon from "./assets/welcome.png";

export const Dashboard = () => {
	const { user } = useAuth0();
	const [message, setMessage] = useState();
	const [companies, setCompanies] = useState();
	const userActions = useUserActions();
	const [showAlert, setShowAlert] = useState(false);
	const [syncingCustomers, setSyncingCustomers]  = useState(false);
	const [syncingCountries, setSyncingCountries]  = useState(false);
	const [syncingCurrencies, setSyncingCurrencies]  = useState(false);

	useEffect(() => {}, []);

	function syncCustomers() {
		setSyncingCustomers(true);
		userActions
			.syncCustomers()
			.then(response => {
				setMessage(response.message);
				if (response.message) {
					setShowAlert(true);
				}
				setCompanies(response.companies);
				setSyncingCustomers(false);
			})
			.catch(error => {
				console.log(error);
			});
	}

	function syncCurrencies() {
		setSyncingCurrencies(true);
		userActions
			.syncCurrencies()
			.then(response => {
				setMessage(response.message);
				if (response.message) {
					setShowAlert(true);
				}
				setSyncingCurrencies(false);
			})
			.catch(error => {
				console.log(error);
			});
	}

	function syncCountries() {
		setSyncingCountries(true);
		userActions
			.syncCountries()
			.then(response => {
				setMessage(response.message);
				if (response.message) {
					setShowAlert(true);
				}
				setSyncingCountries(false);
			})
			.catch(error => {
				console.log(error);
			});
	}

	const toggleShowAlert = () => {
		setShowAlert(!showAlert);
	};

	return (
		<MainLayout>
			<div className="d-flex flex-column flex-center text-center p-10">
					<div className="card-body py-15 py-lg-20">
						<h1 className="fw-bolder text-gray-900 mb-5">Hi, {user?.nickname}!</h1>
						<div className="fw-semibold fs-6 text-gray-500 mb-7">You can sync your ledger customers companies using the button bellow!</div>
					</div>
					{companies && (
						<div>
							<h3>New Companies: </h3>
							<ul className="list-group">
								{companies.validCompaniesInserted.map(companyInserted => (
									<li className="list-group-item text-success" key={companyInserted.data.id}>
										{companyInserted.data.name}
									</li>
								))}
							</ul>

							{companies.validCompaniesUpdated.length > 0 && (
								<div>
									<h3>Updated Companies: </h3>
									<ul className="list-group">
										{companies.validCompaniesUpdated.map(companyToUpdated => (
											<li className="list-group-item text-warning" key={companyToUpdated.data.id}>
												{companyToUpdated.data.name}
											</li>
										))}
									</ul>
								</div>
							)}

							{companies.invalidCompaniesUpdated.length > 0 && (
								<div>
									<h3>Invalid Companies Updated: </h3>
									<ul className="list-group">
										{companies.invalidCompaniesUpdated.map(company => (
											<li className="list-group-item text-danger" key={company.data.id}>
												{company.data.name}
											</li>
										))}
									</ul>
								</div>
							)}

							{companies.invalidCompaniesToInsert.length > 0 && (
								<div>
									<h3>Invalid Companies to Insert: </h3>
									<ul className="list-group">
										{companies.invalidCompaniesToInsert.map(companyInvalid => (
											<li className="list-group-item text-danger" key={companyInvalid.data.id}>
												{companyInvalid.data.name}
											</li>
										))}
									</ul>
								</div>
							)}
						</div>
					)}

					{showAlert && (
						<div className="alert alert-success alert-dismissible fade show" role="alert">
							<strong>Holy guacamole!</strong> {message}!
							<button
								type="button"
								className="close"
								data-dismiss="alert"
								aria-label="Close"
								onClick={toggleShowAlert}
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
					)}
					<div className="mb-4">
						<button disabled={syncingCustomers} onClick={syncCustomers} className="btn btn-md btn-primary">
							<span className="spinner-border spinner-border-sm mr-2" style={{ display: syncingCustomers ?  "inline-block" : "none" }} role="status" aria-hidden="true"></span>
							Sync Customers
						</button>
					</div>

					<div className="card-body py-15 py-lg-20">
						<div className="fw-semibold fs-6 text-gray-500 mb-7">This button is going to sync the oskar supported currencies!</div>
					</div>
				<div className="mt-1 mb-0">
					<button disabled={syncingCurrencies} onClick={syncCurrencies} className="btn btn-md btn-primary">
						<span className="spinner-border spinner-border-sm mr-2" style={{ display: syncingCurrencies ?  "inline-block" : "none" }} role="status" aria-hidden="true"></span>
						Sync Currencies
					</button>
				</div>
					<div className="card-body py-15 py-lg-20">
						<div className="fw-semibold fs-6 text-gray-500 mb-7">This button is going to sync the oskar supported countries!</div>
					</div>
				<div className="mt-1 mb-0">
					<button disabled={syncingCountries} onClick={syncCountries} className="btn btn-md btn-primary">
						<span className="spinner-border spinner-border-sm mr-2" style={{ display: syncingCountries ?  "inline-block" : "none" }} role="status" aria-hidden="true"></span>
						Sync Countries
					</button>
				</div>
					<div className="mb-0">
						<img src={welcomeIcon} className="mw-100 mh-300px theme-light-show" alt="" />
					</div>
			</div>

		</MainLayout>
	);
};
