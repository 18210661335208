import React from "react";
import "./styles.css";
import { InputTypes } from "./Enums";
const identificationDocField = "identificationDoc";
const identificationTypeField = "identificationType";
const addressField = "address";
const additionalInfoField = "additionalInfo";
const additionalInfo = [
	"thirdPartyFunding",
	"position",
	"employmentStatus",
	"sourceOfFunds",
	"remitterBeneficiaryRelationship",
	"transferToCountry",
	"reasonForTransfer",
	"isPep",
	"industryType",
];
const DynamicForm = ({ formData, onFormChange }) => {
	const addRfiResponseInfo = (rfiIndex, newState, hashId, parentField, inputId, value) => {
		if (rfiIndex === -1) {
			newState.push({
				rfiHashId: hashId,
				rfiResponseInfo: {
					[parentField]: {
						[inputId]: value,
					},
				},
			});
		} else {
			newState[rfiIndex].rfiResponseInfo[parentField][inputId] = value;
		}
	};

	const addBaseRfiResponseInfo = (rfiIndex, newState, hashId, inputId, value) => {
		if (rfiIndex === -1) {
			newState.push({
				rfiHashId: hashId,
				rfiResponseInfo: {
					[inputId]: value,
				},
			});
		} else {
			newState[rfiIndex].rfiResponseInfo[inputId] = value;
		}
	};

	const handleInputChange = (value, inputId, hashId, formData) => {
		onFormChange(prevState => {
			const newState = [...prevState];
			let rfiIndex = newState.findIndex(rfi => rfi.rfiHashId === hashId);
			let parentField = formData.description;
			if (parentField === addressField) {
				addRfiResponseInfo(rfiIndex, newState, hashId, parentField, inputId, value);
			} else if (additionalInfo.includes(formData.description)) {
				parentField = additionalInfoField;
				addRfiResponseInfo(rfiIndex, newState, hashId, parentField, inputId, value);
			} else if (formData.type === "document") {
				parentField = identificationDocField;
				addRfiResponseInfo(rfiIndex, newState, hashId, parentField, inputId, value);
			} else {
				addBaseRfiResponseInfo(rfiIndex, newState, hashId, inputId, value);
			}

			return newState;
		});
	};
	const handleFileChange = (files, inputId, hashId, identificationType) => {
		onFormChange(prevState => {
			const newState = [...prevState];
			let rfiIndex = newState.findIndex(rfi => rfi.rfiHashId === hashId);

			if (rfiIndex === -1) {
				newState.push({
					rfiHashId: hashId,
					rfiResponseInfo: {
						[identificationDocField]: {
							[identificationTypeField]: identificationType,
							[inputId]: Array.from(files),
						},
					},
				});
			} else {
				newState[rfiIndex].rfiResponseInfo[identificationDocField][inputId] = Array.from(files);
			}

			return newState;
		});
	};

	return (
		<form className="dynamic-form">
			<div key={formData.rfiHashId} className="form-section">
				<h3>{`Details for: ${formData.transactionEntityType}`}</h3>
				{formData.requiredData.map((formItem, subIndex) => (
					<div key={subIndex} className="form-item">
						<label htmlFor={formItem.value}>{formItem.label}</label>
						{formItem.type === InputTypes.DOCUMENT ? (
							<input
								type="file"
								id={formItem.value}
								name={formItem.value}
								className="form-input"
								onChange={e =>
									handleFileChange(
										e.target.files,
										formItem.value,
										formData.rfiHashId,
										formData.description
									)
								}
								multiple={true}
								required
							/>
						) : (
							<input
								type="text"
								id={formItem.value + subIndex}
								name={formItem.value}
								className="form-input"
								onChange={e =>
									handleInputChange(e.target.value, formItem.value, formData.rfiHashId, formData)
								}
								required
							/>
						)}
					</div>
				))}
			</div>
		</form>
	);
};
export { DynamicForm };
