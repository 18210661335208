import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import Pagination from 'react-bootstrap/Pagination';
import { useUserActions } from '../actions/user.actions';

const AgentsTable = forwardRef((props, ref) => {

  const userActions = useUserActions();
  const [showModal, setShowModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const inputRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [selectedLimit, setSelectedLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [agents, setAgents] = useState();

  const getAgents = async () => {
    userActions
      .getAgents(selectedLimit, page)
      .then(response => {
        const totalPages = Math.ceil(response.totalCount / response.limit);
        setAgents(response.agents);
        setTotalPages(totalPages);
        setCurrentPage(page);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const handleGenerateURL = (agent) => {
    const affiliateURL = generateAffiliateURL(agent);
    setSelectedAgent(affiliateURL);
    setCopied(false);
    setShowModal(true);
  };

  const handleCopyAffiliateURL = () => {
    if (selectedAgent) {
      navigator.clipboard.writeText(selectedAgent);
      setCopied(true);
    }
  };

  const generateAffiliateURL = (agent) => {
    return `${process.env.REACT_APP_AGENT_AFFILIATE_URL}?utm=${encodeURIComponent(agent.short_url_code)}`
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    setSelectedLimit(newLimit);
    setPage(1);
  };

  useImperativeHandle(ref, () => ({
    refreshAgents() {
      getAgents();
    }
  }));

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
    }
    getAgents();
  }, [selectedAgent, selectedLimit, page]);

  return (
    <div className="row">
      {agents && agents.length > 0 && (
        <div className="col-12">
          <h3 className="mb-2 mt-4">Agents</h3>
          <div className="pagination-controls">
            <label className='form-label mt-1'>Limit:</label>
            <div className='ml-2'>
            <Form.Control
              as="select" value={selectedLimit} className='form-control-sm' onChange={handleLimitChange}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </Form.Control>
            </div>
          </div>
          <table className="table table-bordered">
            <thead>
            <tr>
              <th scope="col">Agent Id</th>
              <th scope="col">Agent Name</th>
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col" className="col-4">Companies</th>
              <th scope="col">Status</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            {agents.map(agent => (
              <tr key={"tr-" + agent.agent_id}>
                <td>{agent.agent_id}</td>
                <td>{agent.agent_name}</td>
                <td>{agent.first_name}</td>
                <td>{agent.last_name}</td>
                <td>{agent.companies ? Object.values(agent.companies).join(", ") : ""}</td>
                <td className={agent.is_blocked ? "bg-danger text-white" : "bg-success text-white"}>{agent.is_blocked ? "Terminated" : "Active"}</td>
                <td>
                  <button type="button" className="btn btn-sm btn-primary" key={"generateLinkButton-" + agent.agent_id} onClick={() => handleGenerateURL(agent)}>Generate Link</button>
                </td>
              </tr>
            ))}
            </tbody>
          </table>

          <div className="pagination-container">
            <Pagination>
              <Pagination.First onClick={() => setPage(1)} disabled={currentPage === 1} />
              <Pagination.Prev onClick={() => setPage(currentPage - 1)} disabled={currentPage === 1} />

              {Array.from({ length: totalPages }, (_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={currentPage === index + 1}
                  onClick={() => setPage(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}

              <Pagination.Next onClick={() => setPage(currentPage + 1)} disabled={currentPage === totalPages} />
              <Pagination.Last onClick={() => setPage(totalPages)} disabled={currentPage === totalPages} />
            </Pagination>
          </div>
        </div>
      )}
      {selectedAgent && (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Generated URL</Modal.Title>
          </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <div className="input-group">
              <Form.Control as="textarea" rows={3}
                            cols={50}
                            ref={inputRef} value={selectedAgent} readOnly />
                <div className="ml-2">
                <Button
                  variant="primary"
                  onClick={handleCopyAffiliateURL}
                >
                  <FontAwesomeIcon icon={faCopy} /> {copied ? 'Copied!' : 'Copy URL'}
                </Button>
              </div>
            </div>
          </Form.Group>
        </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>)}
    </div>
  );
});

export default AgentsTable;
